/**
 =========================================================
 * Material Dashboard 2 React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Container from "@mui/material/Container";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import bgImage from "../../assets/images/bg-sign-in-basic.jpeg";

import { loginUser, useAuthDispatch, useAuthState } from "../../context";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors: validationErrors },
  } = useForm({});

  const image = bgImage;
  const [loggedIn, setLoggedIn] = useState(false);

  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAuthDispatch();
  const { token } = useAuthState();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async (data) => {
    setError(false);
    setIsLoading(true);

    const { email, password, companyName } = data;
    try {
      setIsLoading(false);
      const response = await loginUser(dispatch, { email, password, companyName });
      if (!response.user) {
        setError(true);
        return;
      }
      await setLoggedIn(true);
    } catch (error) {
      setError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      setLoggedIn(true);
    }
  }, [loggedIn]);

  return loggedIn === true ? (
    <Navigate to="/home" />
  ) : (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  LOGIPLATFORMS SİSTEM GİRİŞİ
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      required
                      error={validationErrors.email}
                      {...register("email", {
                        pattern: { value: /\S+@\S+\.\S+/ },
                        required: true,
                      })}
                      type="email"
                      label="Email"
                      fullWidth
                    />
                    {validationErrors.email && (
                      <MDTypography variant="caption" color="error">
                        Lutfen gecerli bir e-mail giriniz
                      </MDTypography>
                    )}
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      required
                      error={validationErrors.password}
                      {...register("password", { required: true })}
                      type="password"
                      label="Şifre"
                      fullWidth
                    />
                  </MDBox>
                  {validationErrors.password && (
                    <MDTypography variant="caption" color="error">
                      Lütfen şifre giriniz
                    </MDTypography>
                  )}

                  <MDBox mb={2}>
                    <MDInput
                      required
                      error={validationErrors.companyName}
                      {...register("companyName", { required: true })}
                      type="text"
                      label="Firma"
                      fullWidth
                    />
                  </MDBox>
                  {validationErrors.companyName && (
                    <MDTypography variant="caption" color="error">
                      Lutfen Firma adi giriniz
                    </MDTypography>
                  )}

                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleSetRememberMe}
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                        ml: -1,
                      }}
                    >
                      &nbsp;&nbsp;Beni Hatirla
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      loading={isLoading}
                      disabled={isLoading}
                      o
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSubmit(handleLogin)}
                    >
                      Giriş Yap
                    </MDButton>
                  </MDBox>
                  {error && (
                    <MDBox mt={3} mb={1} textAlign="center">
                      <MDTypography variant="button" color="text">
                        E-mail veya sifre yanlis
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox position="absolute" width="100%" bottom={0} py={4}>
        <Container>
          <MDBox
            width="100%"
            display="flex"
            flexDirection={{
              xs: "column",
              lg: "row",
            }}
            justifyContent="space-between"
            alignItems="center"
            px={1.5}
          >
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              color="white"
              fontSize={10}
            >
              Created by ABK
            </MDBox>
          </MDBox>
        </Container>
      </MDBox>
    </PageLayout>
  );
};

export default Login;
