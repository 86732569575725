import { React, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import SuccessMessage from "../../../components/CustomComponents/Notifications/SuccessMessage";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";

import { statusInputFieldsMeta as inputFieldsMeta } from "../../Common/statusUpdateInputFieldsMeta";
import Form from "../../../components/CustomComponents/CreateEditForms/Form";

import {
  callAPIGetLast5CustomerStatusUpdates,
  callAPICreateCustomerStatusUpdate,
  callAPIUpdateCustomerStatusUpdate, callAPIGetCustomerStatusUpdateDetails
} from "../../../apiCalls/customerStatusUpdatesApiCalls";

function CreateEditCustomerStatusUpdates() {
  const url = window.location.href;
  const urlParams = new URL(url);
  const mode = urlParams.searchParams.get("mode");
  const customerId = urlParams.searchParams.get("customerId");
  const customerStatusUpdateId = urlParams.searchParams.get("statusUpdateId");
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    reset,
    formState: { errors: validationErrors },
  } = useForm();

  useEffect(() => {
    if (mode === "edit") {
      getStatusUpdateDetails();
    }
  }, []);

  const setExistingValuesForForm = async (data) => {
    const { comment } = data;
    formRef.current.setValue("comment", comment);
  };

  const getStatusUpdateDetails = async () => {
    setIsLoading(true);

    try {
      const result = await callAPIGetCustomerStatusUpdateDetails({
        customerStatusUpdateId,
      });

      if (result.status === 200) {
        await setExistingValuesForForm(result.data.data);
        setIsLoading(false);
        setIsError(false);
      } else {
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Müşteri güncelleme detayları yüklenirken bir hata oluştu. Hata detayı: ",
          result.data.message
        );
      }
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Müşteri güncelleme yüklenirken bir hata oluştu.");
    }
  };

  const createNewCustomerStatusUpdate = async (data) => {
    try {
      const resultCreateNewCustomerStatusUpdate = await callAPICreateCustomerStatusUpdate(
        { customerId },
        data
      );
      if (resultCreateNewCustomerStatusUpdate.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        reset();
        setSuccessMessage(`Güncellemeniz başarıyla oluşturuldu.`);
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Güncellemeniz oluşturulurken bir hata oluştu. Hata detayı: ",
          resultCreateNewCustomerStatusUpdate.data.message
        );
      }
    } catch (err) {
      console.log(err);
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Güncellemeniz oluşturulurken bir hata oluştu.");
    }
  };

  const updateCustomerStatusUpdate = async (data) => {
    try {
      const resultUpdatedCustomerStatusUpdate = await callAPIUpdateCustomerStatusUpdate(
        { customerStatusUpdateId },
        data
      );
      if (resultUpdatedCustomerStatusUpdate.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`Müşteri son durum detayı başarıyla güncellendi.`);
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Müşteri son durum detayı güncellenirken bir hata oluştu. Hata detayı: ",
          resultUpdatedCustomerStatusUpdate.data.message
        );
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Müşteri son durum detayı güncellenirken bir hata oluştu.");
    }
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    const commentData = {
      ...data,
    };

    if (mode === "edit") {
      updateCustomerStatusUpdate(commentData);
    } else {
      createNewCustomerStatusUpdate(commentData);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Form
        ref={formRef}
        mode={mode}
        handleSave={handleSave}
        isLoading={isLoading}
        title="Müşteri Son Durum Güncellemesi"
        inputFields={inputFieldsMeta}
      />
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <SuccessMessage
        close={() => setIsSuccess(false)}
        successMessage={successMessage}
        show={isSuccess}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CreateEditCustomerStatusUpdates;
