import {
  currencies,
  freightTypes,
  invoiceTypes,
  transportTypes,
  defaultCurrency,
  countries,
} from "../../../constants/referenceData";

export const dropdownFields = [
  "contractType",
  "forwarderId",
  "employeeId",
  "customerId",
  "truckId",
  "driverId",
  "loadingCountry",
  "offLoadingCountry",
  "transportType",
  "freightType",
  "customerInvoiceCurrency",
  "forwarderInvoiceCurrency",
  "vehicleTowTruckId",
  "vehicleTruckId",
  "vehicleTrailerId",
];

export const inputFieldsMeta = ({
  documentTypes,
  trucks,
  drivers,
  forwarders,
  employees,
  customers,
  alreadySelectedDropdownValues,
  ownTransportContract,
  trailers,
  towTrucks,
}) => [
  {
    dividerTitle: "Fatura Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "contractDate",
    label: "Taşıma Tarihi",
    isRequired: true,
    isFullWidth: true,
    type: "date",
    componentType: "input",
  },
  {
    fieldName: "contractType",
    selectedValue: alreadySelectedDropdownValues?.contractType,
    errorMessage: `Fatura Tipi zorunlu alan.Lütfen doldurunuz.`,
    label: "Fatura Tipi Seçiniz",
    isRequired: false,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: invoiceTypes,
    dropdownWithId: false,
  },
  {
    selectedValue: alreadySelectedDropdownValues?.employeeId,
    fieldName: "employeeId",
    errorMessage: `Operator zorunlu alan.Lütfen doldurunuz.`,
    label: "Operator Seçiniz",
    isRequired: true,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: employees,
    labelController: "name surname",
    dropdownWithIdValue: true,
  },
  {
    dividerTitle: !ownTransportContract ? "Nakliyeci Bilgileri" : "Tasiyici Bilgileri",
    componentType: "dividerHeader",
  },
  !ownTransportContract && {
    selectedValue: alreadySelectedDropdownValues?.forwarderId,
    fieldName: "forwarderId",
    label: "Nakliyeci Seçiniz",
    isRequired: true,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: forwarders,
    labelController: "name",
    dropdownWithIdValue: true,
  },
  !ownTransportContract && {
    fieldName: "forwarderPlateNumber",
    label: "Plaka",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  !ownTransportContract && {
    fieldName: "forwarderDriverName",
    label: "Şoför Adı",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  !ownTransportContract && {
    fieldName: "forwarderDriverSurname",
    label: "Şoför Soyadı",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  !ownTransportContract && {
    fieldName: "forwarderDriverPassportIDNumber",
    label: "Şoför Pasaport/Kimlik No",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  ownTransportContract && {
    selectedValue: alreadySelectedDropdownValues?.vehicleTrailerId,
    fieldName: "vehicleTrailerId",
    label: "Çekici Seçiniz",
    isRequired: true,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: trailers,
    labelController: "plateNumber",
    dropdownWithIdValue: true,
    dataTestId: "trailer-vehicle-dropdown"
  },
  ownTransportContract && {
    selectedValue: alreadySelectedDropdownValues?.vehicleTowTruckId,
    fieldName: "vehicleTowTruckId",
    label: "Dorse Seçiniz",
    isRequired: false,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: towTrucks,
    labelController: "plateNumber",
    dropdownWithIdValue: true,
    dataTestId: "tow-truck-dropdown"
  },
  ownTransportContract && {
    selectedValue: alreadySelectedDropdownValues?.driverId,
    fieldName: "driverId",
    label: "Şoför Seçiniz",
    isRequired: true,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: drivers,
    labelController: "name surname",
    dropdownWithIdValue: true,
  },

  {
    dividerTitle: "Müşteri Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "customerId",
    selectedValue: alreadySelectedDropdownValues?.customerId,
    errorMessage: `Müşteri  zorunlu alan.Lütfen doldurunuz.`,
    label: "Müşteri Seçiniz",
    isRequired: true,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: customers,
    labelController: "name",
    dropdownWithIdValue: true,
  },
  {
    dividerTitle: "Yükleme Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "loadingCountry",
    selectedValue: alreadySelectedDropdownValues?.loadingCountry,
    label: "Yükleme Ülkesi",
    isRequired: false,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: countries,
    labelController: "name",
    dropdownWithIdValue: false,
  },
  {
    fieldName: "departingDate",
    label: "Çıkış Tarihi",
    isRequired: false,
    isFullWidth: true,
    type: "date",
    componentType: "input",
  },
  {
    fieldName: "departingCustom",
    label: "Çıkış Gümrüğü",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    fieldName: "departingAddress",
    label: "Yükleme Adresi",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    dividerTitle: "Varış Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "offLoadingCountry",
    selectedValue: alreadySelectedDropdownValues?.offLoadingCountry,
    label: "Varış Ülkesi",
    isRequired: false,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: countries,
    labelController: "name",
    dropdownWithIdValue: false,
  },
  {
    fieldName: "arrivingDate",
    label: "Varış Tarihi",
    isRequired: false,
    isFullWidth: true,
    type: "date",
    componentType: "input",
  },
  {
    fieldName: "arrivingCustom",
    label: "Varış Gümrüğü",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    fieldName: "arrivingAddress",
    label: "Boşaltma Adresi",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    dividerTitle: "Yük Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "freightType",
    selectedValue: alreadySelectedDropdownValues?.freightType,
    label: "Yük Tipi",
    isRequired: false,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: freightTypes,
    dropdownWithId: false,
  },
  {
    fieldName: "transportType",
    selectedValue: alreadySelectedDropdownValues?.transportType,
    label: "Araç Tipi",
    isRequired: false,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: transportTypes,
    dropdownWithId: false,
  },
  {
    fieldName: "freightDetails",
    label: "Yük Detayı",
    isRequired: false,
    isFullWidth: true,
    type: "text",
    componentType: "input",
  },
  {
    label: "Boyutlar",
    componentType: "multipleInputsInOneLine",
    inputs: {
      input: {
        fieldName: "width",
        width: 100,
        label: "En(cm)",
        isRequired: false,
        isFullWidth: false,
        type: "number",
        componentType: "input",
      },
      input2: {
        fieldName: "height",
        width: 100,
        label: "Boy(cm)",
        isRequired: false,
        isFullWidth: false,
        type: "number",
        componentType: "input",
      },
      input3: {
        fieldName: "length",
        width: 100,
        label: "Uzunluk(cm)",
        isRequired: false,
        isFullWidth: false,
        type: "number",
        componentType: "input",
      },
    },
  },
  {
    fieldName: "quantity",
    label: "Adet",
    isRequired: false,
    isFullWidth: true,
    type: "number",
    componentType: "input",
  },
  {
    fieldName: "weight",
    label: "Ağırlık(kg)",
    isRequired: false,
    isFullWidth: true,
    type: "number",
    componentType: "input",
  },
  {
    dividerTitle: "Müşteri Fiyat Bilgileri",
    componentType: "dividerHeader",
  },
  {
    fieldName: "customerInvoicePriceValue",
    label: "Müşteri Fiyatı",
    isRequired: true,
    isFullWidth: true,
    type: "number",
    componentType: "input",
  },
  {
    fieldName: "customerInvoiceDueInDays",
    label: "Vade(gün)",
    isRequired: false,
    isFullWidth: true,
    type: "number",
    componentType: "input",
  },
  {
    fieldName: "customerInvoiceCurrency",
    selectedValue: alreadySelectedDropdownValues?.customerInvoiceCurrency || defaultCurrency,
    label: "Para Birimi",
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: currencies,
    dropdownWithId: false,
  },
  !ownTransportContract && {
    dividerTitle: "Nakliyeci Fiyat Bilgileri",
    componentType: "dividerHeader",
  },
  !ownTransportContract && {
    fieldName: "forwarderInvoicePriceValue",
    label: "Nakliyeci Fiyatı",
    isRequired: true,
    isFullWidth: true,
    type: "number",
    componentType: "input",
  },
  !ownTransportContract && {
    fieldName: "forwarderInvoiceDueInDays",
    label: "Vade(gün)",
    isRequired: false,
    isFullWidth: true,
    type: "number",
    componentType: "input",
  },
  !ownTransportContract && {
    fieldName: "forwarderInvoiceCurrency",
    selectedValue: alreadySelectedDropdownValues?.forwarderInvoiceCurrency || defaultCurrency,
    label: "Para Birimi",
    isRequired: false,
    isFullWidth: true,
    componentType: "dropdown",
    dropdownValues: currencies,
    dropdownWithId: false,
  },
  {
    fieldName: "documents",
    documentTypes,
    label: "Dosya Yükle",
    isRequired: false,
    isFullWidth: true,
    componentType: "uploadFile",
  },
];
