import {
  callAPIAddDocument,
  callAPIDownloadDocument,
  callAPIRemoveDocument,
} from "apiCalls/documentApiCalls";
import { showConfirmAlert } from "../../components/CustomComponents/Alert";

export const createDocument = async (documentDetails) => {
  const { documents, entity, entityId } = documentDetails;

  try {
    documents.map(async (doc) => {
      const formData = new FormData();
      formData.append("file", doc[0]);
      formData.append("docType", doc.docType);
      formData.append("entity", entity);
      formData.append("expiryDate", doc.expiryDate || null);
      formData.append("entityId", entityId || null);
      const responseForAddingDoc = await callAPIAddDocument(formData);
      if (!responseForAddingDoc.status === 200) {
        alert("Dosya yüklenemedi.");
      }
    });
  } catch (err) {
    alert("Dosya yüklenemedi.", err);
  }
};

export const handleDocumentDownload = async (fileId) => {
  try {
    const result = await callAPIDownloadDocument({ fileId });
    const filename = `logiplatforms_${Date.now().toFixed()}.png`;
    const blob = result.data;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a); // append the element to the dom
    a.click();
    a.remove(); // afterwards, remove the element

    if (result.status !== 200) {
      alert("Dosya yüklenemedi.");
    }
  } catch (err) {
    alert("Dosya yüklenemedi.");
  }
};

