import { React, useRef, useEffect, useState } from "react";
import Form from "components/CustomComponents/CreateEditForms/Form";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import LoaderSpinner from "components/CustomComponents/Loader/LoaderSpinner";
import { inputFieldsMeta, nrOfFieldsInContacts } from "./inputFieldsMeta";
import SuccessMessage from "../../../components/CustomComponents/Notifications/SuccessMessage";
import ErrorMessage from "../../../components/CustomComponents/Notifications/ErrorMessage";
import {
  callAPICreateNewForwarder,
  callAPIGetForwarderDetails,
  callAPIUpdateForwarder,
} from "../../../apiCalls/forwarderApiCalls";

import {
  callAPICreateForwarderContacts,
  callAPIGetForwarderContacts,
  callAPIUpdateForwarderContacts,
} from "../../../apiCalls/forwarderContactsApiCalls";

function CreateEditForwarder() {
  const alreadySelectedDropdownValues = {};
  let existingForwarderValues = {};
  let existingForwarderContactValues = {};

  const url = window.location.href;
  const urlParams = new URL(url);
  const mode = urlParams.searchParams.get("mode");
  const formRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [inputFieldsMetaData, setInputFieldsMetaData] = useState(null);

  const getForwarderContacts = async () => {
    const id = urlParams.searchParams.get("forwarderId");
    try {
      const forwarderContactResponse = await callAPIGetForwarderContacts({ forwarderId: id });
      if (forwarderContactResponse.status === 200) {
        setIsLoading(false);
        setIsError(false);
        return forwarderContactResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Nakliyeci irtibat kisileri yüklenirken bir hata oluştu. Hata detayı: ",
        forwarderContactResponse.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Nakliyeci irtibat kisileri yüklenirken bir hata oluştu.");
    }
  };

  const getForwarderDetails = async () => {
    const id = urlParams.searchParams.get("forwarderId");
    try {
      const forwarderDetailsResponse = await callAPIGetForwarderDetails({
        forwarderId: id,
      });
      if (forwarderDetailsResponse.status === 200) {
        setIsLoading(false);
        setIsError(false);
        return forwarderDetailsResponse.data.data;
      }

      setIsSuccess(false);
      setIsError(true);
      setIsLoading(false);
      setErrorMessage(
        "Nakliyeci yüklenirken bir hata oluştu. Hata detayı: ",
        forwarderDetailsResponse.data.message
      );
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Nakliyeci yüklenirken bir hata oluştu.");
    }
  };

  const getDropdownIndexesForExistingValues = async () => {
    const { country } = existingForwarderValues;
    if (country) {
      alreadySelectedDropdownValues.country = country;
    }
  };

  const setExistingValuesForForm = async () => {
    for (const [key, value] of Object.entries(existingForwarderValues)) {
      if (
        key !== "id" ||
        value !== null ||
        value !== "" ||
        key === "createdAt" ||
        key === "updatedAt"
      ) {
        formRef.current.setValue(key, value);
      }
    }

    for (let i = 0; i < existingForwarderContactValues.length; i++) {
      for (const [key, value] of Object.entries(existingForwarderContactValues[i])) {
        if (
          key !== "id" ||
          value !== null ||
          value !== "" ||
          key === "createdAt" ||
          key === "updatedAt"
        ) {
          formRef.current.setValue(`forwarderContacts.${key}${i + 1}`, value);
        }
      }
    }
  };

  useEffect(async () => {
    if (mode === "edit" || mode === "copy") {
      existingForwarderValues = await getForwarderDetails();
      existingForwarderContactValues = await getForwarderContacts();
      await getDropdownIndexesForExistingValues();
      await setInputFieldsMetaData(
        inputFieldsMeta({
          contactSize: existingForwarderContactValues.length || 1,
          alreadySelectedDropdownValues,
        })
      );
      await setExistingValuesForForm();
      setIsLoading(false);
    } else {
      await setInputFieldsMetaData(inputFieldsMeta({}));
      setIsLoading(false);
    }
  }, []);

  const forwarderContactHasValue = async (forwarderContacts, counter) => {
    const valuesAreNotEmpty = !(
      forwarderContacts[`name${counter + 1}`] === "" &&
      forwarderContacts[`surname${counter + 1}`] === "" &&
      forwarderContacts[`phone${counter + 1}`] === "" &&
      forwarderContacts[`email${counter + 1}`] === ""
    );
    const valuesAreNotUndefined = !(
      forwarderContacts[`name${counter + 1}`] === undefined &&
      forwarderContacts[`surname${counter + 1}`] === undefined &&
      forwarderContacts[`phone${counter + 1}`] === undefined &&
      forwarderContacts[`email${counter + 1}`] === undefined
    );

    return valuesAreNotEmpty && valuesAreNotUndefined;
  };

  const forwarderContactsToBeSaved = async ({ forwarderContacts, forwarderId }) => {
    // Get total count of contacts. If update mode there are 4 more fields
    const totalNewContactCount =
      Object.keys(forwarderContacts).length /
      (mode === "edit" ? nrOfFieldsInContacts + 4 : nrOfFieldsInContacts);
    const forwarderContactsData = [];

    for (let i = 0; i < totalNewContactCount; i++) {
      // Don't create if there is no data
      if (await forwarderContactHasValue(forwarderContacts, i) === true) {
        const forwarderContact = {
          name: forwarderContacts[`name${i + 1}`],
          surname: forwarderContacts[`surname${i + 1}`],
          phone: forwarderContacts[`phone${i + 1}`],
          email: forwarderContacts[`email${i + 1}`],
          forwarderId,
        };
        forwarderContactsData.push(forwarderContact);
      }
    }
    return forwarderContactsData;
  };

  const createForwarderContacts = async ({ forwarderContacts, forwarderId }) => {
    const forwarderContactsData = await forwarderContactsToBeSaved({
      forwarderContacts,
      forwarderId,
    });
    console.log(forwarderContactsData);

    if (forwarderContactsData.length !== 0) {
      try {
        const resultCreateNewForwarderContacts = await callAPICreateForwarderContacts(
          forwarderContactsData
        );
        if (resultCreateNewForwarderContacts.status !== 200) {
          setIsError(true);
          setErrorMessage(
            "Nakliyeci kontaklari oluşturulurken bir hata oluştu. Hata detayı: ",
            resultCreateNewForwarderContacts.data.message
          );
        }
      } catch (err) {
        setIsError(true);
        setErrorMessage("Nakliyeci kontaklari oluşturulurken bir hata oluştu.");
      }
    }
  };

  const updateForwarderContacts = async ({ forwarderContacts, forwarderId }) => {
    const forwarderContactsData = await forwarderContactsToBeSaved({
      forwarderContacts,
      forwarderId,
    });

    if (forwarderContactsData.length !== 0) {
      try {
        const resultUpdateForwarderContacts = await callAPIUpdateForwarderContacts(
          null,
          forwarderContactsData
        );
        if (resultUpdateForwarderContacts.status !== 200) {
          setIsError(true);
          setErrorMessage(
            "Nakliyeci kontaklari güncellenirken bir hata oluştu. Hata detayı: ",
            resultUpdateForwarderContacts.data.message
          );
        }
      } catch (err) {
        setIsError(true);
        setErrorMessage("Nakliyeci kontaklari güncellenirken bir hata oluştu.");
      }
    }
  };

  const handleSave = async (data) => {
    setIsLoading(true);

    if (mode === "edit") {
      updateForwarder(data);
    } else {
      createNewForwarder(data);
    }
  };

  const updateForwarder = async (data) => {
    const { forwarderContacts, ...updatedForwarderData } = data;

    try {
      const resultUpdatedForwarder = await callAPIUpdateForwarder(
        null,
        updatedForwarderData
      );
      if (resultUpdatedForwarder.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`${data.shortName} isimli nakliyeci  başarıyla güncellendi.`);

        updateForwarderContacts({
          forwarderContacts,
          forwarderId: updatedForwarderData.id,
        });
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Nakliyeci güncellenirken bir hata oluştu. Hata detayı: ",
          resultUpdatedForwarder.data.message
        );
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Nakliyeci güncellenirken bir hata oluştu.");
    }
  };

  const createNewForwarder = async (data) => {
    setIsLoading(true);

    const { forwarderContacts, ...newForwarderData } = data;
    try {
      const resultCreateNewForwarder = await callAPICreateNewForwarder(
        newForwarderData
      );
      if (resultCreateNewForwarder.status === 200) {
        setIsLoading(false);
        setIsSuccess(true);
        setIsError(false);
        setSuccessMessage(`${newForwarderData.shortName} isimli nakliyeci başarıyla oluşturuldu.`);

        await createForwarderContacts({
          forwarderContacts,
          forwarderId: resultCreateNewForwarder.data.data.id,
        });
        await formRef.current.resetForm();
      } else {
        setIsSuccess(false);
        setIsError(true);
        setIsLoading(false);
        setErrorMessage(
          "Nakliyeci oluşturulurken bir hata oluştu. Hata detayı: ",
          resultCreateNewForwarder.data.message
        );
      }
    } catch (err) {
      setIsSuccess(false);
      setIsLoading(false);
      setIsError(true);
      setErrorMessage("Nakliyeci oluşturulurken bir hata oluştu.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {inputFieldsMetaData ? (
        <Form
          ref={formRef}
          mode={mode}
          handleSave={handleSave}
          isLoading={isLoading}
          title={`Nakliyeci ${mode === "edit" ? "Güncelle" : "Oluştur"} `}
          inputFields={inputFieldsMetaData}
        />
      ) : (
        <LoaderSpinner />
      )}
      <ErrorMessage close={() => setIsError(false)} errorMessage={errorMessage} show={isError} />
      <SuccessMessage
        close={() => setIsSuccess(false)}
        successMessage={successMessage}
        show={isSuccess}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CreateEditForwarder;
