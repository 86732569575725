import { objectToQueryString, baseURL, callGETMethodAPI, callPOSTMethodAPI, callPUTMethodAPI } from "./index";

export const callAPIGetLast5CustomerStatusUpdates = async (params) => {
  const url = `${`${baseURL}/customerStatusUpdates/getLast5CustomerStatusUpdates` + "?"}${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};

export const callAPICreateCustomerStatusUpdate = async (params, body) => {
  const url = `${baseURL}/customerStatusUpdates/createNewCustomerStatusUpdate?${objectToQueryString(params)}`;
  return callPOSTMethodAPI(url, body);
};

export const callAPIGetCustomerStatusUpdateDetails = async (params) => {
  const url = `${
    `${baseURL}/customerStatusUpdates/getCustomerStatusUpdateDetails` + "?"
  }${objectToQueryString(params)}`;
  return callGETMethodAPI(url);
};


export const callAPIUpdateCustomerStatusUpdate = async (params, body) => {
  const url = `${`${baseURL}/customerStatusUpdates/updateCustomerStatusUpdate` + "?"}${objectToQueryString(params)}`;
  return callPUTMethodAPI(url, body);
};
