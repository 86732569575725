// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import AirlineSeatLegroomExtraTwoToneIcon from "@mui/icons-material/AirlineSeatLegroomExtraTwoTone";

// Utils
import { commonActionsColumn } from "pages/Common/commonActionsColumnsInTable";
import { actionBaseColumns } from "pages/Common/baseContractDataTable";
import { returnShortDateString } from "../../../utils/dateUtils";
import { ENTITY_NAMES } from "constants/entityNames";

export const generateDriversTableColumnsRowsData = async (data) => {
  const columns = [
    {
      Header: "",
      accessor: "icon",
      align: "right",
      width: "1%",
    },
    {
      Header: "İsim Soyİsim",
      accessor: "name",
      align: "left",
    },
    {
      Header: "Doğum Tarihi",
      accessor: "birthday",
      align: "center",
    },
    {
      Header: "Adres",
      accessor: "address",
      align: "center",
    },
    {
      Header: "Ülke/Şehir",
      accessor: "country",
      align: "left",
    },
    {
      Header: "Telefon",
      accessor: "phone",
      align: "center",
    },
    ...actionBaseColumns,
  ];

  const rows = [];

  data.forEach((d) => {
    const {
      name,
      surname,
      address,
      city,
      country,
      birthday,
      phone,
      id,
    } = d;
    const actionColumnParams = {
      id,
      entity: ENTITY_NAMES.driver,
      isEditMode: true,
      isCopyMode: true,
      details: false,
      download: false,
      remove: true,
      documents: false,
      addVisa: true,
      listVisas: true,
    };

    rows.push({
      icon: <AirlineSeatLegroomExtraTwoToneIcon color="info" fontSize="medium" />,
      name: `${name} ${surname || ""}`,
      address: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {address}
        </MDTypography>
      ),
      country: (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {country}
          </MDTypography>
          <MDTypography variant="caption">{city}</MDTypography>
        </MDBox>
      ),
      birthday: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {birthday && returnShortDateString(birthday)}
        </MDTypography>
      ),
      phone: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {phone}
        </MDTypography>
      ),
      actions: commonActionsColumn(actionColumnParams),
    });
  });

  return {
    rows,
    columns,
  };
};
