export const statusInputFieldsMeta = [
  {
    fieldName: 'comment',
    label: 'Durum Güncelleme Detayı/Yorum/Son Gorusme',
    isRequired:true,
    isFullWidth:true,
    componentType:'input',
    type: 'text',
    maxLength: '300'
  }
]
