import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import './language/config';

// Soft UI Context Provider
import { MaterialUIControllerProvider } from "context";

import { AuthProvider } from './context';
  ReactDOM.render(
    <BrowserRouter>
      <AuthProvider>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </AuthProvider>
    </BrowserRouter>,
    document.getElementById("root"));
